import { registerApplication, start } from 'single-spa'

registerApplication(
  'menu',
  () => import('./menu/menu.app.js'),
  () => true,
)

registerApplication(
  'questionsBase',
  () => import('./src/index'),
  (location) => location.pathname.startsWith('/'),
)

start()
